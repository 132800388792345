<template>
    <div v-if="loading == false">
        <ChirpList
            :data_source="rows"
            :export_api="export_api"
            :totalCount="totalCount"
            @applyCriteria="modifyCriteria"
            :column_meta="columnMeta"
            @rowSelected="onRowSelected"
            :loadingColumns="loadingTable"
            :storeKey="storeKey"
            :useOverride="useOverride"
            @cancelOverride="handleCancelOverride"
        />
    </div>
    <div v-else>Loading...</div>
</template>

<script>
    import { tryGetFilter } from '@/util/tryGetFilter';

    const api_root = 'invoices/list';

    import ChirpList from '@/components/general/list/ChirpList';

    export default {
        name: 'Invoices',
        components: { ChirpList },
        props: {
            overrideFilter: {
                type: Object,
                default: null
            }
        },
        data() {
            return {
                rows: [],
                totalCount: 0,
                useOverride: false,
                targetPage: 'Invoice',
                loading: true,
                loadingTable: false,
                criteriaProps: {},
                export_api: '/invoices/list',
                columnMeta: [
                    { field: 'client_name', headerText: 'Client' },
                    { field: 'date', headerText: 'Date' },
                    { field: 'status', headerText: 'Status', allowSorting: true },
                    { field: 'amount_due', headerText: 'Amount Due', allowSorting: true },
                    { field: 'amount_paid', headerText: 'Amount Paid', allowSorting: true },
                    { field: 'amount_credited', headerText: 'Amount Credited', allowSorting: true },
                    { field: 'invoice_num', headerText: 'Invoice #', allowSorting: true },

                ]
            };
        },
        computed: {
            criteria() {
                return this.useOverride && this.overrideFilter ? this.overrideCriteria : this.savedCriteria;
            },
            savedCriteria() {
                return tryGetFilter(this.$store, this.storeKey);
            },
            overrideCriteria() {
                return this.$store.getters['filters/overrideCriteria'](this.storeKey);
            },
            storeKey() {
                return this.pageMeta?.page || 'invoices';
            }
        },
        methods: {
            async onRowSelected(args) {
                this.$router.push({ name: this.targetPage, params: { invoice_id: args.id } });
            },
            async load_data() {
                try {
                    const body = {
                        criteria: this.criteria
                    };
                    const res = await this.$api.post(this.export_api, body);

                    if (res.status >= 400) {
                        this.$toasted.error('Failed to fetch invoice roster.');
                        return;
                    }

                    this.rows = res.data.rows;

                    this.totalCount = res.data.total_count;
                } catch (err) {
                    this.$toasted.error('Failed to fetch invoice roster.');
                }
                this.$nextTick(() => {
                    this.loading = false;
                });
            },
            async init() {
                if (this.overrideFilter) {
                    this.useOverride = true;
                    this.$store.commit('filters/applyOverride', {
                        stateKey: this.storeKey,
                        criteria: this.overrideFilter
                    });
                }

                if (!this.criteria.sort || (this.criteria.sort && Object.keys(this.criteria.sort).length === 0)) {
                    this.$store.commit('filters/applyDefaultDateSort', { stateKey: this.storeKey });
                }

                await this.load_data();
            },
            async modifyCriteria(criteria) {
                if (this.targetPage) {
                    this.$store.commit('filters/update', {
                        criteriaPage: this.targetPage,
                        payload: criteria
                    });
                }
                this.criteriaProps = criteria;
                this.load_data();
            },
            handleCancelOverride() {
                this.useOverride = false;
            },
        },
        async created() {
            await this.init();
        },
        watch: {
            criteria: {
                deep: true,
                async handler() {
                    await this.load_data();
                }
            }
        }
    };
</script>
